$primary: #29a9e2;
$secondary: #5b38b2;

$bg-primary:  #6e6c6c;
$bg-secondary: #5b38b2;

$primary-dark: #6e6c6c;
$primary-light: white;

$dark-bg: #333333;

body {
    font-size: 16px;

    background-color: $dark-bg;
    color: white;

    font-family: Arial, Helvetica, sans-serif;

    padding-left: 4em;
    padding-right: 4em;
    
    margin: 0;
}

nav {
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 9000;
    //background-color: $dark-bg;
}

nav ul {
    list-style: none;
    float: right;
}

nav ul li {
    //border-top: 2px solid transparent;
    padding: 1em;
}

nav ul li:hover {
    //border-top: 2px solid $primary;
    cursor: pointer;
}

#main-nav {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    padding-left: 4em;
    padding-right: 4em;
}

#main-nav a img {
    pointer-events: none;
}

#main-nav a.active {
    color: $primary;
}

#main-nav a {
    color: white;

    text-decoration: none;
}

.js-loading * {
//.js-loading *:before,
//.js-loading *:after {
    animation-play-state: paused !important;
}

#home {
    animation: show-background 0.25s .5s ease-in forwards;
    opacity: 0;
}

#home ul li {
    display: inline-block;
    list-style: circle;
}

footer {
    display: grid;
    grid-template-columns: 30% 15% 15% 40%;

    ul {
        list-style: none;
    }

    a {
        color: white;
        text-decoration: none;
    }

}

/*footer ul {
    list-style: none;
}

footer {
    display: grid;
    grid-template-columns: 30% 15% 15% 40%
}*/

ul.horizontal-group li {
    display: inline-block;
    padding: 0 1em;
}

section {

    //padding: 95px 4em;
    padding: 0 4em;
    margin-left: -4em;
    margin-right: -4em;

    background-color: $secondary;

    //margin-bottom: 5px;
    height: 100vh;
}

.full-height {
    height: 100vh;
}

.small-text {
    font-size: smaller;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.section-light {
    background-color: $primary-light;
    color: $primary-dark;
}

.section-primary {
    background-color: $primary;
    color: $primary-light;
}

.section-secondary {
    background-color: $secondary;
    color: $primary-light;
}

@keyframes show-background {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1.0;
    }
}
