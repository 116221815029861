body {
  color: #fff;
  background-color: #333;
  margin: 0;
  padding-left: 4em;
  padding-right: 4em;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

nav {
  z-index: 9000;
  padding-top: 20px;
  padding-bottom: 20px;
}

nav ul {
  float: right;
  list-style: none;
}

nav ul li {
  padding: 1em;
}

nav ul li:hover {
  cursor: pointer;
}

#main-nav {
  padding-left: 4em;
  padding-right: 4em;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

#main-nav a img {
  pointer-events: none;
}

#main-nav a.active {
  color: #29a9e2;
}

#main-nav a {
  color: #fff;
  text-decoration: none;
}

.js-loading * {
  animation-play-state: paused !important;
}

#home {
  opacity: 0;
  animation: .25s ease-in .5s forwards show-background;
}

#home ul li {
  list-style: circle;
  display: inline-block;
}

footer {
  grid-template-columns: 30% 15% 15% 40%;
  display: grid;
}

footer ul {
  list-style: none;
}

footer a {
  color: #fff;
  text-decoration: none;
}

ul.horizontal-group li {
  padding: 0 1em;
  display: inline-block;
}

section {
  background-color: #5b38b2;
  height: 100vh;
  margin-left: -4em;
  margin-right: -4em;
  padding: 0 4em;
}

.full-height {
  height: 100vh;
}

.small-text {
  margin-block: 1em;
  font-size: smaller;
}

.section-light {
  color: #6e6c6c;
  background-color: #fff;
}

.section-primary {
  color: #fff;
  background-color: #29a9e2;
}

.section-secondary {
  color: #fff;
  background-color: #5b38b2;
}

@keyframes show-background {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
/*# sourceMappingURL=index.f78debbb.css.map */
